
import { defineComponent, PropType } from 'vue'
import { Route } from '@/models/Route'
import dayjs from 'dayjs'
import { parameterizeString } from '@/utils/strings'

export default defineComponent({
  props: {
    route: {
      type: Object as PropType<Route>,
      required: true
    }
  },
  setup (props) {
    const destination = {
      name: 'Route',
      params: {
        routeNumber: props.route.id.replace('/', '-'),
        routeName: parameterizeString(props.route.name),
        forDate: dayjs().format('YYYY-MM-DD')
      }
    }

    return {
      destination
    }
  }
})
