
import {
  defineComponent,
  reactive,
  toRefs,
  computed,
  ref,
  ComputedRef,
  PropType,
  watchEffect,
  onMounted
} from 'vue'
import {
  hoursList
} from '@/utils/ContactUsRulesAndLists'
import PinIconRed from '@/components/vectors/PinIconRed.vue'
import Settings from '@/components/vectors/Settings.vue'
import {
  Service,
  Stop,
  TimetableByDate,
  Timetable
} from '@/models/Timetable'
import DatePickerVue from '@/components/DatePicker/DatePicker.vue'
import dayjs from 'dayjs'
import Spinner from '@/components/Spinner.vue'
import LegendTable from '@/components/Timetables/LegendTable.vue'
import { filterLegendList } from '@/utils/Legends'
import { Legend } from '@/models/Legend'
import { DayOption } from '@/models/DayOption'
import {
  getLegends,
  getDayOptions
} from '@/expressway-api/timetable.api'
import PrintedHeader from './PrintedHeader.vue'
import TimetableTable from './TimetableTable.vue'
import ArrowDropdown from '@/components/vectors/ArrowDropdown.vue'
import advancedFormat from 'dayjs/plugin/advancedFormat'

export default defineComponent({
  components: {
    PinIconRed,
    Settings,
    DatePickerVue,
    Spinner,
    LegendTable,
    PrintedHeader,
    TimetableTable,
    ArrowDropdown
  },
  props: {
    timetables: {
      type: Object as PropType<TimetableByDate[]>,
      required: true
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    },
    outboundTripName: {
      type: String,
      required: true
    },
    inboundTripName: {
      type: String,
      required: true
    },
    routeNumber: {
      type: String,
      required: true
    },
    routeName: {
      type: String,
      required: true
    },
    files: {
      required: true,
      type: Array as PropType<string[]>
    }
  },
  // eslint-disable-next-line max-lines-per-function
  setup (props) {
    dayjs.extend(advancedFormat)
    const filteringName = ref('')
    const hours = hoursList
    const today = dayjs().format('YYYY-MM-DD')
    const maxDate = dayjs().add(90, 'day').format('YYYY-MM-DD')
    const showValidOnDatePicker = ref(false)
    const hardlLegends = ref<Legend[]>([])
    const dayOptions = ref<DayOption[]>([])
    const downloadingTimetable = ref(false)
    const state = reactive({
      dayFilter: [
        'SuX',
        'M-F',
        'M-Th',
        'MO',
        'TuO',
        'WO',
        'ThO',
        'FO',
        'SO',
        'SuO',
        'FSuO',
        'SSuO'
      ],
      stopFilter: '',
      directionFilter: 'Inbound',
      showFilters: false,
      validOn: today
    })
    const cssPagedMedia = document.createElement('style')
    const currentValidity = computed(() =>
      currentTimetable.value === props.timetables[0].Timetable
        ? `Valid to ${dayjs(state.validOn).format('Do MMMM YYYY')}`
        : `Valid from ${dayjs(state.validOn)}`
    )
    dayjs(state.validOn)
    document.head.appendChild(cssPagedMedia)

    const filterText = computed(() =>
      state.showFilters ? 'Close' : 'Filter'
    )

    const services: ComputedRef<Service[]> = computed(() =>
      currentTimetable.value[state.directionFilter].filter(
        service => (
          (service.InServiceSymbol === '' ||
          state.dayFilter.includes(service.InServiceSymbol)) &&
          dayjs(service.ValidFrom) <= dayjs(state.validOn) &&
          dayjs(service.ValidTo) >= dayjs(state.validOn)
        )
      )
    )

    const inverseServices: ComputedRef<Service[]> = computed(() =>
      currentTimetable.value[inverseDirectionFilter.value].filter(
        service => (
          (service.InServiceSymbol === '' ||
          state.dayFilter.includes(service.InServiceSymbol)) &&
          dayjs(service.ValidFrom) <= dayjs(state.validOn) &&
          dayjs(service.ValidTo) >= dayjs(state.validOn)
        )
      )
    )

    const stops: ComputedRef<Stop[]> = computed(() => {
      let filteredStops: Stop[] = []

      if (state.directionFilter === 'Outbound') {
        filteredStops = currentTimetable.value.OutboundStops
      } else {
        filteredStops = currentTimetable.value.InboundStops
      }

      if (filteringName.value) {
        filteredStops = filteredStops.filter(
          stop => stop.Name.toLowerCase().includes(filteringName.value.toLowerCase())
        )
      }

      return filteredStops
    })

    const inverseStops: ComputedRef<Stop[]> = computed(() => {
      let filteredStops: Stop[] = []

      if (state.directionFilter === 'Outbound') {
        filteredStops = currentTimetable.value.InboundStops
      } else {
        filteredStops = currentTimetable.value.OutboundStops
      }

      if (filteringName.value && filteringName.value.length >= 3) {
        filteredStops = filteredStops.filter(
          stop => stop.Name.toLowerCase().includes(filteringName.value.toLowerCase())
        )
      }

      return filteredStops
    })

    getLegends().then(res => { hardlLegends.value = res })
    getDayOptions().then(res => { dayOptions.value = res })

    const allLegends: ComputedRef<Legend[]> = computed(() => {
      const customLegends = currentTimetable.value.TripInformation.filter(customLegend =>
        customLegend.match(/.{1,4}=[ ]*.+/))

      const parsedCustomLegends = customLegends.map(customLegend => {
        const acronym = customLegend.split('=')[0].trim()
        const legendName = customLegend.split('=')[1].trim()

        return {
          Acronym: acronym,
          Name: legendName,
          Type: acronym
        } as Legend
      })

      return [...hardlLegends.value, ...parsedCustomLegends]
    })

    const tripInformation: ComputedRef<string[]> = computed(() =>
      currentTimetable.value.TripInformation.filter(customNote =>
        !customNote.match(/.{1,4}=[ ]*.+/)))

    const legends: ComputedRef<Legend[]> = computed(() => {
      const legendTypes: string[] = []

      services.value.forEach(service => {
        if (service.InServiceSymbol) {
          legendTypes.push(service.InServiceSymbol)
        }

        stops.value.forEach(stop => {
          const serviceStop = service?.Stops[stop.Key]

          if (serviceStop?.CustomNote) {
            legendTypes.push(serviceStop.CustomNote)
          } else if (serviceStop?.TypeId) {
            legendTypes.push(serviceStop.TypeId)
          }
        })
      })

      const uniqueLegendTypes = [...new Set(legendTypes)]

      return filterLegendList(allLegends.value, uniqueLegendTypes)
    })

    const printedLegends: ComputedRef<Legend[]> = computed(() => {
      const legendTypes: string[] = []

      const servs = [...services.value, ...inverseServices.value]
      const allStops = [...stops.value, ...inverseStops.value]

      servs.forEach(service => {
        if (service.InServiceSymbol) {
          legendTypes.push(service.InServiceSymbol)
        }

        allStops.forEach(stop => {
          const serviceStop = service?.Stops[stop.Key]

          if (serviceStop?.CustomNote) {
            legendTypes.push(serviceStop.CustomNote)
          } else if (serviceStop?.TypeId) {
            legendTypes.push(serviceStop.TypeId)
          }
        })
      })

      const uniqueLegendTypes = [...new Set(legendTypes)]

      return filterLegendList(allLegends.value, uniqueLegendTypes)
    })

    const toggleDirectionFilter = () => {
      state.directionFilter = state.directionFilter === 'Inbound' ? 'Outbound' : 'Inbound'
    }

    const stopLegend = (typeId: string): string => {
      if (typeId === 'BUS_STOP_TYPE.REQUEST_STOP_PICK_UP') {
        return 'P'
      } else if (typeId === 'BUS_STOP_TYPE.REQUEST_STOP_DROP_OFF') {
        return 'D'
      }

      return ''
    }

    const currentTimetable: ComputedRef<Timetable> = computed(() =>
      props.timetables.find(timetable =>
        timetable.ValidFrom === state.validOn)?.Timetable || props.timetables[0].Timetable)

    const orderedDates = computed(() =>
      props.timetables.map(timetable => timetable.ValidFrom)
        .sort((a, b) => {
          if (dayjs(a) < dayjs(b)) {
            return -1
          } else {
            return 1
          }
        })
    )

    const directionText = computed(() => {
      if (props.outboundTripName) {
        return state.directionFilter === 'Outbound' ? props.outboundTripName : props.inboundTripName
      } else {
        const currentStops = state.directionFilter === 'Outbound'
          ? currentTimetable.value.OutboundStops
          : currentTimetable.value.InboundStops

        return `${currentStops[0].ShortName} to ${currentStops[currentStops.length - 1].ShortName}`
      }
    })

    const inverseDirectionText = computed(() => {
      if (props.outboundTripName) {
        return state.directionFilter === 'Outbound' ? props.inboundTripName : props.outboundTripName
      } else {
        return `${inverseStops.value[0].ShortName}
                to ${inverseStops.value[inverseStops.value.length - 1].ShortName}`
      }
    })

    const inverseDirectionFilter = computed(() =>
      state.directionFilter === 'Outbound' ? 'Inbound' : 'Outbound')

    // eslint-disable-next-line complexity
    const fontSizeClass = computed(() => {
      const totalStops = stops.value.length + inverseStops.value.length
      const maxServices = Math.max(services.value.length, inverseServices.value.length)

      if (orientation.value === 'landscape') {
        if (maxServices >= 30) {
          return 'text-30s'
        } else if (maxServices >= 25 || totalStops >= 45) {
          return 'text-25s'
        } else if (maxServices >= 20 || totalStops >= 37) {
          return 'text-20s'
        } else if (maxServices >= 18 || totalStops >= 30) {
          return 'text-18s'
        } else if (maxServices >= 16 || totalStops >= 23) {
          return 'text-15s'
        } else {
          return 'text-12s'
        }
      } else {
        if (totalStops >= 90 || maxServices >= 25) {
          return 'text-25s'
        } else if (totalStops >= 80 || maxServices >= 18) {
          return 'text-23s'
        } else if (totalStops >= 70 || maxServices >= 18) {
          return 'text-20s'
        } else if (totalStops >= 30 || maxServices >= 16) {
          return 'text-18s'
        } else if (totalStops >= 25 || maxServices >= 15) {
          return 'text-15s'
        } else {
          return 'text-12s'
        }
      }
    })

    // eslint-disable-next-line complexity
    const orientation = computed(() => {
      const totalStops = stops.value.length + inverseStops.value.length
      const maxServices = Math.max(services.value.length, inverseServices.value.length)

      if (maxServices >= 25) {
        return 'landscape'
      } else if (totalStops >= 50) {
        return 'portrait'
      } else {
        return 'landscape'
      }
    })

    watchEffect(() => {
      cssPagedMedia.innerHTML = `@page {margin: 0; size: A4 ${orientation.value}}`
    })

    const downloadTimetable = () => {
      downloadingTimetable.value = true

      props.files.forEach(file => {
        const link = document.createElement('a')
        link.setAttribute('href', file)
        link.setAttribute('target', '_blank')
        link.setAttribute('download', props.routeName || 'timetable')
        document.body.appendChild(link)
        link.click()
      })

      downloadingTimetable.value = false
    }

    onMounted(() => {
      const [firstStop] = props.routeName.split('-')

      if (props.timetables[0].Timetable.InboundStops[0].Name.toLowerCase().includes(
        firstStop.toLowerCase())) {
        state.directionFilter = 'Inbound'
      } else {
        state.directionFilter = 'Outbound'
      }
    })

    return {
      stops,
      hours,
      toggleDirectionFilter,
      filterText,
      dayOptions,
      services,
      stopLegend,
      filteringName,
      today,
      maxDate,
      showValidOnDatePicker,
      legends,
      currentTimetable,
      orderedDates,
      directionText,
      inverseDirectionText,
      inverseStops,
      inverseServices,
      fontSizeClass,
      downloadTimetable,
      currentValidity,
      printedLegends,
      downloadingTimetable,
      tripInformation,
      dayjs,
      ...toRefs(state)
    }
  }
})
